/*  typography */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap');

body {
  line-height: 1.7;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  color: $text-color;
  font-size: 15px;
}

p {
  font-weight: 400;
  color: $text-color;
  font-size: 15px;
  line-height: 1.7;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  color: $text-color-dark;
  font-family: $primary-font;
  font-weight: 800;
  line-height: 1.2;
}

h1, .h1{
  font-size: 52px;
}

h2, .h2{
  font-size: 36px;
}

h3, .h3{
  font-size: 24px;
}

h4, .h4{
  font-size: 18px;
}

h5, .h5{
  font-size: 15px;
}

h6, .h6{
  font-size: 13px;
}
