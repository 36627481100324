// Color Variables
$primary-color: #4FD675;
$text-color: #555;
$text-color-dark: #333;
$text-color-light: #777;
$body-color: #fff;
$border-color: #EBEBEB;
$black: #000;
$white: #fff;
$light: #F9F9F9;

// Font Variables
$primary-font: 'Nunito Sans', sans-serif;
$icon-font: 'themify';