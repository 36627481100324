/* Button style */
.btn {
  font-size: 14px;
  font-family: $primary-font;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 22px;
  border-radius: 50px;
  border: 1px solid;
  position: relative;
  z-index: 1;
  transition: .2s ease;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.btn-primary {
  background: $primary-color;
  color: $white;
  border-color: $primary-color;

  &:active,
  &:hover,
  &.focus,
  &.active {
    background: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.btn-outline-primary {
  background: transparent;
  color: $primary-color;
  border-color: $primary-color;

  &:active,
  &:hover,
  &.focus,
  &.active {
    background: $primary-color !important;
    border-color: $primary-color !important;
    color: $white;
  }
}